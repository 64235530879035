import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeSquare, faPhoneSquare } from '@fortawesome/free-solid-svg-icons';
import { faTwitterSquare, faGithubSquare } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <Container className='page-container'>
      <Row>
        <Col>
        <Card
          bg='dark'
          text='white'
          className='mb-2'
        >
          <Card.Body>
            <Card.Title>Daimen Worrall</Card.Title>
            <Card.Text>
              My website is currently somewhere in the aether. Check back soon.<br />
              In the meantime, feel free to get in touch.
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              Unsolicited recruitment or advertisement is not welcome.
            </Card.Subtitle>
            <Card.Link href='mailto:daimen@worrall.pw?subject=Website%20Contact' target='_blank'>
              <FontAwesomeIcon icon={faEnvelopeSquare} />
            </Card.Link>
            <Card.Link href='tel:+447908596668' target='_blank'>
              <FontAwesomeIcon icon={faPhoneSquare} />
            </Card.Link>
            <Card.Link href='https://twitter.com/daimenworrall' target='_blank'>
              <FontAwesomeIcon icon={faTwitterSquare} />
            </Card.Link>
            <Card.Link href='https://github.com/daimenworrall' target='_blank'>
              <FontAwesomeIcon icon={faGithubSquare} />
            </Card.Link>
          </Card.Body>
        </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
